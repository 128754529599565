<template>
	<div :class="isDark ? 'dark': 'light'">
		<div class="background">
			<div id="heading" class="box">
				Pointing Poker by Joshi
			</div>

			<div class="theme-switch" @click="toggleTheme">
				<div :class="{'theme-active': isDark}">
					<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z"/></svg>
				</div>
				<div :class="{'theme-active': !isDark}">
					<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z"/></svg>
				</div>
			</div>
		
			<router-view/>

			<Cookies ref="cookies"></Cookies>
		</div>
		<Footer @openCookieSettings="openCookies"></Footer>
	</div>
</template>

<script>
import './styles/dark.css';
import './styles/light.css';
import './styles/spielekiste.css';

import Cookies from '@/components/Cookies.vue';
import Footer from '@/components/Footer.vue';

export default {
	name: 'App',
	components: {
		Cookies,
		Footer
	},
	data() {
		return {
			isDark: false,
			cookieSettings: true,
		}
	},
	created() {
		const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const savedTheme = JSON.parse(localStorage.getItem('isDark'));
    if (savedTheme === null) {
      this.isDark = userPrefersDark;
    } else {
      this.isDark = savedTheme;
    }
	},
	watch: {
		isDark(newVal) {
      localStorage.setItem('isDark', JSON.stringify(newVal));
    }
	},
	methods: {
		toggleTheme() {
			this.isDark = !this.isDark;
		},
		openCookies() {
			this.$refs.cookies.showBanner = true;
		}
	}
}
</script>

<style>
@font-face {
	font-family: 'Roboto';
	src: url('./assets/Roboto-Regular.ttf') format('truetype');
}

body {
	margin: 0;
}

#app {
	font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.background {
	box-sizing: border-box;
	width: 100vw;
	min-height: 100vh;
	padding: 10px;
	background-color: var(--md-sys-color-background);
}

.box {
  background-color: var(--md-sys-color-secondary);
	color: var(--md-sys-color-on-secondary);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 80%;
  max-width: 690px;
	box-sizing: border-box;
  margin: 0 auto;
	font-size: 24px;
}

.theme-switch {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 55px;
	height: 30px;
	border: 3px solid var(--md-sys-color-outline);
	border-radius: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	fill: var(--md-sys-color-on-surface);
}

.theme-active {
	background-color: var(--md-sys-color-tertiary);
	border-radius: 50%;
	box-sizing: border-box;
}

@media (max-width: 600px) {
  .box {
    width: 90%;
  }
}
</style>
